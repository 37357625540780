import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { AlignCenter } from "react-bootstrap-icons";
import Img1 from "../assets/img/pmbd2.png";
import Img2 from "../assets/img/dweb1.png";
import Img3 from "../assets/img/uxui.png";
import Img4 from "../assets/img/mantenimiento.png";

export const Skills = () => {
  return (
    <div className="skillsMain" id="skills">
      <section className="skill skill-bx">
        <Row>
          <Col className="introSkills" xs={12} md={12} lg={12}>
            <h3>
              Trabajamos en desarrollo web y de aplicaciones. Nuestro equipo
              diverso y talentoso se enorgullece de su capacidad para ofrecer
              soluciones digitales innovadoras y personalizadas. Aquí te
              presentamos nuestras características clave:
            </h3>
          </Col>
        </Row>
        <br></br>
        <Row style={{ background: "#ffffff00" }}>
          <Col className="skillCol" xs={12} md={6} lg={6}>
            <div className="container">
              <div className="card" style={{ background: "#ffffff00" }}>
                <div className="slide slide1">
                  <div className="content">
                    <div className="icon">
                      <img src={Img1} alt="Blair Díaz Corp." />
                    </div>
                  </div>
                </div>

                <div className="slide slide2" style={{ background: "#ffffff" }}>
                  <div className="content">
                    <h3>Empresa Familiar</h3>

                    <p>
                      Somos una empresa familiar comprometida con la excelencia.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col className="skillCol" xs={12} md={6} lg={6}>
            <div className="container">
              <div className="card" style={{ background: "#ffffff00" }}>
                <div className="slide slide1">
                  <div className="content">
                    <div className="icon">
                      <img src={Img2} alt="Blair Díaz Corp." />
                    </div>
                  </div>
                </div>

                <div className="slide slide2" style={{ background: "#ffffff" }}>
                  <div className="content">
                    <h3>Desarrollo Web</h3>

                    <p>Creación de sitios web atractivos y funcionales.</p>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <br></br>
        <Row style={{ background: "#ffffff00" }}>
          <Col className="skillCol" xs={12} md={6} lg={6}>
            <div className="container">
              <div className="card" style={{ background: "#ffffff00" }}>
                <div className="slide slide1">
                  <div className="content">
                    <div className="icon">
                      <img src={Img3} alt="Blair Díaz Corp." />
                    </div>
                  </div>
                </div>

                <div className="slide slide2" style={{ background: "#ffffff" }}>
                  <div className="content">
                    <h3>Diseño UX/UI</h3>

                    <p>Creación de experiencias de usuario intuitivas.</p>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col className="skillCol" xs={12} md={6} lg={6}>
            <div className="container">
              <div className="card" style={{ background: "#ffffff00" }}>
                <div className="slide slide1">
                  <div className="content">
                    <div className="icon">
                      <img src={Img4} alt="Blair Díaz Corp." />
                    </div>
                  </div>
                </div>

                <div className="slide slide2" style={{ background: "#ffffff" }}>
                  <div className="content">
                    <h3>Optimización</h3>

                    <p>Aseguramiento de tiempos de carga rápidos.</p>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </section>
    </div>
  );
};
