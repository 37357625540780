import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/w1.png";
import projImg2 from "../assets/img/w2.png";
import projImg3 from "../assets/img/w3.png";
import projImg4 from "../assets/img/w4.png";
import projImg5 from "../assets/img/w5.png";
import projImg6 from "../assets/img/w6.png";
import projImg7 from "../assets/img/bssn.png";
import projImg8 from "../assets/img/virtualstores.png";
import projImg9 from "../assets/img/chiletalagante.png";
import colorSharp2 from "../assets/img/color-sharp2.png";
import "animate.css";
import TrackVisibility from "react-on-screen";

export const Projects = () => {
  const projects = [
    {
      title: "Business Deal",
      description: "HTML - CSS - JS",
      imgUrl: projImg1,
      dirUrl: "https://blairdiaz.com/webs/1/index.html",
    },
    {
      title: "Business Illusion",
      description: "Diseño y Desarrollo",
      imgUrl: projImg2,
      dirUrl: "https://blairdiaz.com/webs/2/HTML/index.html",
    },
    {
      title: "Sylk",
      description: "Portafolio Fotógrafo",
      imgUrl: projImg3,
      dirUrl: "https://blairdiaz.com/webs/3/web/index.html",
    },
    {
      title: "Business APOLLO",
      description: "Diseño de portafolio",
      imgUrl: projImg4,
      dirUrl: "https://blairdiaz.com/webs/4/index.html",
    },
    {
      title: "Business APOLLO",
      description: "Diseño de portafolio",
      imgUrl: projImg5,
      dirUrl: "https://blairdiaz.com/webs/5/index.html",
    },
    {
      title: "Sugar Land",
      description: "Portafolio Fotográfico",
      imgUrl: projImg6,
      dirUrl: "https://blairdiaz.com/webs/6/www/index.html",
    },
  ];

  const projectsWordpress = [
    {
      title: "BSSN USA",
      description: "Wordpress - Elementor Pro",
      imgUrl: projImg7,
      dirUrl: "https://www.bssnusa.com/",
    },
    {
      title: "VirtualStores",
      description: "Wordpress - Sliders",
      imgUrl: projImg8,
      dirUrl: "https://virtualstores.us/",
    },
    {
      title: "Cerrajería Chile Talagante",
      description: "Wordpress",
      imgUrl: projImg9,
      dirUrl: "https://cerrajeriachiletalagante.com/",
    },
  ];

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div
                  className={
                    isVisible ? "animate__animated animate__fadeIn" : ""
                  }
                >
                  <h2>Proyectos</h2>
                  <p>
                    Entendemos que elegir a un equipo para trabajar en tus
                    proyectos puede ser un desafío, especialmente cuando ese
                    equipo es nuevo y aún no ha acumulado mucha experiencia
                    laboral. Sin embargo, en nuestra empresa, creemos que la
                    falta de experiencia laboral no es un obstáculo, sino una
                    oportunidad para aprender y crecer.
                  </p>
                  <Tab.Container id="projects-tabs" defaultActiveKey="first">
                    <Nav
                      variant="pills"
                      className="nav-pills mb-5 justify-content-center align-items-center"
                      id="pills-tab"
                    >
                      <Nav.Item>
                        <Nav.Link eventKey="first">Proyectos</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second">Wordpress</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="third">Servicios</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="fourth">¡Gracias!</Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content
                      id="slideInUp"
                      className={
                        isVisible ? "animate__animated animate__slideInUp" : ""
                      }
                    >
                      <Tab.Pane eventKey="first">
                        <Row>
                          {projects.map((project, index) => {
                            return <ProjectCard key={index} {...project} />;
                          })}
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        <Row>
                          {projectsWordpress.map((project, index) => {
                            return <ProjectCard key={index} {...project} />;
                          })}
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="third">
                        <p></p>
                        <br></br>
                        <p>
                          <h3>Desarrollo Web:</h3> Aunque somos nuevos en el
                          campo, estamos equipados con las últimas tecnologías y
                          tendencias en desarrollo web.
                        </p>
                        <br></br>
                        <p>
                          <h3>Diseño UX/UI:</h3> Nos apasiona crear experiencias
                          de usuario intuitivas y diseños de interfaz de usuario
                          atractivos. Optimización de Rendimiento: Nos
                          esforzamos por asegurar tiempos de carga rápidos y una
                          experiencia fluida en todos los proyectos en los que
                          trabajamos.
                        </p>
                        <br></br>
                        <p>
                          <h3>Mantenimiento y Seguimiento:</h3> Estamos
                          comprometidos con el servicio post-desarrollo,
                          proporcionando mantenimiento y seguimiento continuo a
                          nuestros clientes.
                        </p>

                        <br></br>
                      </Tab.Pane>
                      <Tab.Pane eventKey="fourth">
                        <p>
                          <br></br>
                          <br></br>
                          
                            <h4>
                              Somos una empresa familiar que valora las
                              relaciones a largo plazo con nuestros clientes.
                            </h4>
                          
                          <br></br>
                          <h4>
                            Estamos emocionados de tener la oportunidad de
                            trabajar contigo y demostrar nuestro compromiso con
                            la excelencia, a pesar de nuestra falta de
                            experiencia laboral. Creemos que nuestra pasión,
                            dedicación y deseo de aprender nos distinguen.
                          </h4>
                        </p>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  );
};
