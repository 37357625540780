import { useRef } from 'react';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';

export const Twak = () => {
  const tawkMessengerRef = useRef();

    const handleMinimize = () => {
        tawkMessengerRef.current.minimize();
    };

    return (
        <div className="App">
            <button onClick={handleMinimize}> Minimize the Chat </button>

            <TawkMessengerReact
                propertyId="6017698ec31c9117cb746df7"
                widgetId="1etdn8m5k"
                ref={tawkMessengerRef}/>
        </div>
    );
}
