import whatsIcon from '../assets/img/whatsapp.svg';

export const Whatsapp = () => {

  return (
    <>
      <div className={'whatsAppIcon'}>
          <a target='_blank' href="https://api.whatsapp.com/send?phone=51994051832&text=%F0%9F%98%83%20hola%20estoy%20interesado%20en%20....">
            <img src={whatsIcon} alt="WhatsApp" />
          </a>
      </div>
    </>
  )
}
