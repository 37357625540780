import { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import contactImg from "../assets/img/contact.png";
import "animate.css";
import TrackVisibility from "react-on-screen";
import whatsIcon from "../assets/img/whatsapp.svg";
import Img3 from "../assets/img/pmbd3.png";

export const Contact = () => {
  const formInitialDetails = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
  };

  return (
    <section className="contact" id="connect">
      <Container>
        <Row
          style={{ backgroundColor: "#ffffff00" }}
          className="align-items-center"
        >
          <Col size={12} md={6}>
            <img
              className={"animate__animated animate__zoomIn"}
              src={Img3}
              alt="Contact Us"
            />
          </Col>
          <Col size={12} md={6}>
            <h2>Contáctanos</h2>
            <p>
              Pero mejor contáctanos por nuestro chat directo que lo encuentras
              flotando por la ventana<br></br>o presiona el botón y
              contáctanos por WhatsApp.
            </p>
            <br></br>
            <p>
              <a
                target="_blank"
                href="https://api.whatsapp.com/send?phone=51994051832&text=%F0%9F%98%83%20hola%20estoy%20interesado%20en%20...."
              >
                <img src={whatsIcon} alt="WhatsApp" />
              </a>
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
